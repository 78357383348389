import { useContext, useEffect, useMemo, useState } from 'react';
import { Toaster } from "react-hot-toast";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DownButton } from '../../../Components/Predict/actionButton/downButton';
import { UpButton } from '../../../Components/Predict/actionButton/upButton';
import PLoader from '../../../Components/Predict/loader/Loader';
import GameContext from '../../../Context/GameContext';
import { useTheme } from '../../../Context/Predict/themeContext';
import { Category } from '../../../Domain/Category/category';
import { CatergoryProvider } from '../../../Domain/Category/categoryProdider';
import { Game } from '../../../Domain/Predict/game';
import { PredictProvider } from '../../../Domain/Predict/predictProdiver';
import LinkIcon from '../../../Resources/Predict/icons/link.svg';
import LinkDarkIcon from '../../../Resources/Predict/icons/link_dark.svg';
import CycleTimerIcon from '../../../Resources/Predict/icons/cycleTimer.svg';
import PixieLogo from '../../../Resources/Predict/images/PixieLogo.png';
import СoinIcon from '../../../Resources/Predict/icons/coin.svg';
import style from './predictionsStyle.module.scss';
import PlusLogo from '../../../Resources/Predict/icons/plus_2.svg'
import menu_predict_dark from '../../../Resources/images/menu/new/predict_dark.svg';
import menu_predict_light from '../../../Resources/images/menu/new/predict_light_color.svg';
import FireIcon from '../../../Resources/Predict/icons/fire.svg';
import UsdtIcon from '../../../Resources/Predict/icons/USDT.svg';
import { TonConnectButton } from '@tonconnect/ui-react';
import ActivePredictionsPage from './activePredictions/activePredictionsPage';
import BalanceCard from './balanceCard/balanceCard';
import { PredictType } from './fast/fastPredictionPage';

export default function PredictionsPage() {
    const { token, userId } = useContext(GameContext);
    const { theme } = useTheme();

    const [games, setGames] = useState<Game[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [gamesIsLoaded, setGamesIsLoaded] = useState<boolean>(false);

    const [menuOpen, setMenuOpen] = useState(false);

    const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(-1);
    const [categories, setCategories] = useState<Category[]>([]);

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        loadCaregories();
    }, [])

    useEffect(() => {
        loadGames();
    }, [selectedCategoryId])


    async function loadCaregories() {
        setIsLoaded(false);

        const categories = await CatergoryProvider.get(token!);
        const categoriesWithYourSelf = [new Category(-1, "All", "Все", "All"), ...categories, new Category(0, "Games feed", "Лента игр", "Games feed"),];

        setCategories(categoriesWithYourSelf);

        setIsLoaded(true);
    }

    async function loadGames() {
        if (selectedCategoryId == null) return;

        setGamesIsLoaded(false);

        const games = await PredictProvider.getGames(selectedCategoryId, token!);
        setGames(games);

        setGamesIsLoaded(true);
    }

    function translatedName(caregory: Category) {
        if (i18n.language === 'ru') {
            return caregory.name_ru;
        } else if (i18n.language === 'uk') {
            return caregory.name_uk;
        } else {
            return caregory.name_en;
        }
    }

    function formatName(name: string | null) {
        if (name == null) return;

        const a = name.split(' ');
        return a[0];
    }

    function handleChangeCategory(categoryId: number) {
        setSelectedCategoryId(categoryId);
    }

    if (!isLoaded) return <PLoader />

    return (
        <div className={style.mainPageContainer}>
            <div className={style.header}>
                <div className={style.header_left}>
                    <div className={style.header_left_logo} onClick={() => navigate("/predict/predictions")}>
                        <img src={PixieLogo} alt="PixieLogo" />
                    </div>
                    {/* <div className={style.header_left_search}>
                        <img src={SearchIcon} alt="SearchIcon" />
                        <input type='text' placeholder='Search' />
                    </div> */}
                </div>
                {/* <div className={style.header_authButton} onClick={() => navigate("/predict/predictions/create")}>
                    <ButtonTelegramAuth />
                </div> */}
                <div className={style.header_right} >
                    <div className={style.header_createButton} onClick={() => navigate("/predict/predictions/create")}>
                        <img src={PlusLogo} alt="PlusLogo" />
                        <img src={theme == 'dark' ? menu_predict_dark : menu_predict_light} alt="Predict" />
                    </div>
                    <BalanceCard />
                </div>
            </div>

            {
                [939941303, 875591451, 215159309, 463600889].includes(userId) &&
                <>
                    <TonConnectButton style={{ float: "right" }} />
                </>
            }

            <div className={style.category_filters}>
                {
                    categories.map(category =>
                        <div key={category.id}
                            onClick={() => handleChangeCategory(category.id)}
                            className={`${style.category_filters_category} ${selectedCategoryId == category.id ? style.active : ''}`}
                        >
                            {translatedName(category)}
                            {category.id == 5 && <img src={UsdtIcon} alt="UsdtIcon" />}
                        </div>
                    )
                }
            </div>
            <div className={style.gameListContainer}>
                {
                    selectedCategoryId == 0
                        ? <ActivePredictionsPage />
                        : gamesIsLoaded
                            ? games.map((game, index) => <GameCard key={index} game={game} isHot={selectedCategoryId == 5} />)
                            : <PLoader />
                }
            </div>
            <div><Toaster /></div>
        </div>
    )
}

interface IGameCardProps {
    game: Game;
    isHot?: boolean
}

export function GameCard({ game, isHot }: IGameCardProps) {
    const { userId } = useContext(GameContext);
    const [timeLeft, setTimeLeft] = useState<number>(0);

    const navigate = useNavigate();
    const { theme } = useTheme();
    const { t } = useTranslation();

    useEffect(() => {
        const gameEndTime = new Date(game.end_at!).getTime();

        const timeRemaining = gameEndTime - new Date().getTime();
        setTimeLeft(Math.max(timeRemaining, 0));

        const timerId = setInterval(() => {
            setTimeLeft(prevTime => Math.max(prevTime - 1000, 0));
        }, 1000);

        return () => clearInterval(timerId);
    }, [game.created_at]);

    const formatTime = (milliseconds: number) => {
        const days = Math.floor(milliseconds / 1000 / 60 / 60 / 24);
        const hours = Math.floor((milliseconds / 1000 / 60 / 60) % 24);
        const minutes = Math.floor((milliseconds / 1000 / 60) % 60);

        return `${days}d ${hours < 10 ? '0' : ''}${hours}h ${minutes < 10 ? '0' : ''}${minutes}m`;
    };

    function navigateToGameProcess(game: Game) {
        if ([971].includes(game.id)) return;

        navigate(`/predict/predictions/${game.id}`)
    }

    function getPredictDescription(id: number) {
        switch (+id!) {
            case 1: return t("Short 1-minute long BTC price prediction games with only two outcomes: higher or lower");
            case 3: return t("Short 5-minute long ETH price prediction games with only two outcomes: higher or lower");
            case 56: return t("Short 10-minute long TON price prediction games with only two outcomes: higher or lower");
            case 59: return t("Short 10-minute long DOGS price prediction games with only two outcomes: higher or lower");
            case 60: return t("Short 10-minute long NOT price prediction games with only two outcomes: higher or lower");
            case 61: return t("Short 10-minute long HAMSTER price prediction games with only two outcomes: higher or lower");
            case 69: return t("Short 1-minute long BTC price prediction games with only two outcomes: higher or lower");
            case 89: return t("Short 10-minute long DOGE price prediction games with only two outcomes: higher or lower");
            case 90: return t("Short 10-minute long PEPE price prediction games with only two outcomes: higher or lower");
            case 91: return t("Short 10-minute long SHIB price prediction games with only two outcomes: higher or lower");
            case 92: return t("Short 5-minute long BTC price prediction games with only two outcomes and two players!");
            case 95: return t("Short 5-minute long BTC price prediction games with only two outcomes and two players!");
            case 96: return t("Short 5-minute long BTC price prediction games with only two outcomes and two players!");
            case 130: return t("Short 1-minute long BTC price prediction games with only two outcomes: higher or lower");
            case 131: return t("Short 1-minute long BTC price prediction games with only two outcomes: higher or lower");
            case 132: return t("Short 1-minute long BTC price prediction games with only two outcomes: higher or lower");
            case 169: return t("Short 1-minute long BTC price prediction games with only two outcomes: higher or lower");
            case 170: return t("Short 1-minute long BTC price prediction games with only two outcomes: higher or lower");
        }
    }

    function formatName(name: string | null) {
        if (name == null) return;

        const a = name.split(' ');
        return a[0];
    }

    const predictType = () => {
        switch (game.id) {
            case 1: return PredictType.btc;
            case 3: return PredictType.eth;
            case 56: return PredictType.ton;
            case 59: return PredictType.dogs;
            case 60: return PredictType.notcoin;
            case 61: return PredictType.hamster;
            case 69: return PredictType.btc;
            case 89: return PredictType.doge;
            case 90: return PredictType.pepe;
            case 91: return PredictType.shib;
            case 92: return PredictType.btcLong;
            case 97: return PredictType.fillgingGlass;
            case 130: return PredictType.btc;
            case 131: return PredictType.btc;
            case 132: return PredictType.btc;
            case 169: return PredictType.btc;
            case 170: return PredictType.btc;
        }
    };

    const fastPredictionIds = [1, 3, 56, 59, 60, 61, 69, 89, 90, 91, 92, 95, 96, 130, 131, 132, 169, 170]

    return (
        <div key={game.id} className={style.gameListContainer_gameCard} onClick={() => navigateToGameProcess(game)}>
            {[971].includes(game.id) && <div className={style.comingSoonContainer}> <span>{t("Coming soon")}...</span> </div>}
            <div className={style.gameListContainer_gameCard_info}>
                <div className={style.gameListContainer_gameCard_info_left}>
                    <img src={game.image ? `${process.env.REACT_APP_FILE_STORAGE_URL}/${game.image}` : ""} alt="" />
                </div>
                <div className={style.gameListContainer_gameCard_info_right}>
                    <div className={style.gameListContainer_gameCard_info_right_hedaer}>
                        <div className={style.gameListContainer_gameCard_info_right_hedaer_left}>
                            <span>
                                {t("Created by")} {game.creator_telegram_id == -1
                                    ? <span >{t("System")}</span>
                                    : (game.last_name != null || game.first_name != null)
                                        ? <span >
                                            {game.first_name != null && `${game.first_name} `}

                                            {game.last_name != null && game.last_name}
                                        </span>
                                        : <span>{t("System")}</span>
                                }
                            </span>
                            {
                                game.id != 97 &&
                                <>
                                    {
                                        !fastPredictionIds.includes(game.id) &&
                                        <div className={style.gameListContainer_gameCard_info_right_gameName_timer}>
                                            <span>{formatTime(timeLeft)}</span>
                                        </div>
                                    }
                                    {
                                        fastPredictionIds.includes(game.id) &&
                                        <div className={style.gameListContainer_gameCard_info_right_gameName_timer}>
                                            <img src={CycleTimerIcon} alt="" />
                                            <span>{predictType() == PredictType.btc ? 1 : (predictType() == PredictType.eth || predictType() == PredictType.btcLong) ? 5 : 10} {t("min")}</span>
                                        </div>
                                    }
                                </>
                            }

                        </div>

                        <div className={style.gameListContainer_gameCard_info_right_hedaer_right}>
                            <a href={`https://t.me/share/url?url=https://t.me/pixie_project_bot?start=predict${game.id}_${userId}&text=${game.description}`}>
                                <img src={theme == "dark" ? LinkDarkIcon : LinkIcon} alt="LinkIcon" />
                            </a>
                            {/* <img onClick={e => { e.stopPropagation() }} src={theme == "dark" ? CommentDarkIcon : CommentLightIcon} alt="CommentDarkIcon" /> */}

                            {/* <img onClick={e => { e.stopPropagation() }} src={theme == "dark" ? OutlineStarDarkIcon : OutlineStarIcon} alt="OutlineStarIcon" /> */}
                        </div>
                    </div>
                    <div className={style.gameListContainer_gameCard_info_right_gameName}>
                        {game.name}
                    </div>
                    {
                        (fastPredictionIds.includes(game.id) || game.id == 97) &&
                        <div className={style.gameListContainer_gameCard_info_right_gameDescription}>
                            {getPredictDescription(game.id)}
                        </div>
                    }
                    {
                        game.id == 97 &&
                        <div className={style.gameListContainer_gameCard_info_right_gameDescriptionPrizePool}>
                            <span>
                                {t("Balance")}: {(+(game.players_sum_bet_up_count ?? 0)).toFixed(3)}
                            </span>
                            <img src={СoinIcon} alt="СoinIcon" />
                        </div>
                    }

                    {/* <div className={style.gameListContainer_gameCard_info_right_statistics}>
                                <div>
                                    <img src={theme == 'dark' ? CalendarDarkIcon : CalendarIcon} alt="CalendarIcon" />
                                    <span>{game.created_at.toLocaleDateString()}</span>
                                </div>
                            </div> */}
                </div>
            </div>

            {
                fastPredictionIds.includes(game.id) &&
                <div className={style.gameListContainer_gameCard_action}>
                    <DownButton
                        title={formatName(game.button_name_down) ?? ""}
                        subTitle={game.coef_down == null ? null : (+(+game.coef_down.toFixed(2))).toLocaleString('en') + "x"}
                        isHot={isHot && game.coef_down != null && game.coef_down > (game.coef_up ?? 0)}
                    />
                    <UpButton
                        title={formatName(game.button_name_up) ?? ""}
                        subTitle={game.coef_up == null ? null : (+(+game.coef_up.toFixed(2))).toLocaleString('en') + "x"}
                        isHot={isHot && game.coef_up != null && game.coef_up > (game.coef_down ?? 0)}
                    />
                </div>
            }

            {
                !fastPredictionIds.includes(game.id) && game.id != 97 &&
                <div className={`${style.gameListContainer_gameCard_action} ${style.coef}`}>
                    <div className={style.gameListContainer_gameCard_action_coef}>
                        <div> {game.button_name_up}</div>
                        <div>{(game.percents_up ?? 0).toFixed(1)}%</div>
                        {(game.coef_up ?? 0) < (game.coef_down ?? 0)
                            ? <DownButton
                                title={game.coef_up == null ? "0x" : (+(+game.coef_up.toFixed(2))).toLocaleString('en') + "x"}
                                withoutIcon
                                isHot={isHot && game.coef_up != null && game.coef_up > (game.coef_down ?? 0)}
                            />
                            : <UpButton
                                title={game.coef_up == null ? "0x" : (+(+game.coef_up.toFixed(2))).toLocaleString('en') + "x"}
                                withoutIcon
                                isHot={isHot && game.coef_up != null && game.coef_up > (game.coef_down ?? 0)}
                            />
                        }
                    </div>
                    <div className={style.gameListContainer_gameCard_action_coef}>
                        <div>{game.button_name_down}</div>
                        <div>{(game.percents_down ?? 0).toFixed(1)}%</div>
                        {(game.coef_down ?? 0) < (game.coef_up ?? 0)
                            ? <DownButton
                                title={game.coef_down == null ? "0x" : (+(+game.coef_down.toFixed(2))).toLocaleString('en') + "x"}
                                withoutIcon
                                isHot={isHot && game.coef_down != null && game.coef_down > (game.coef_up ?? 0)}
                            />
                            : <UpButton
                                title={game.coef_down == null ? "0x" : (+(+game.coef_down.toFixed(2))).toLocaleString('en') + "x"}
                                withoutIcon
                                isHot={isHot && game.coef_down != null && game.coef_down > (game.coef_up ?? 0)}
                            />
                        }
                    </div>
                </div>
            }

        </div>
    )
}