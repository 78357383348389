import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InvoiceProvider } from "../../../../Domain/Invoice/invoiceProvider";
import GameContext from "../../../../Context/GameContext";
import toast, { Toaster } from "react-hot-toast";
import './invoicePaymentModalStyle.scss'
import { RoundedNumberInput } from "../../../../Components/Predict/inputs/roundedTextInput/roundedNumberInput";
import PLoader from "../../../../Components/Predict/loader/Loader";
import { useTranslation } from "react-i18next";
// import { invoice } from '@telegram-apps/sdk';
// import { on } from '@telegram-apps/bridge';
// import { defineEventHandlers } from '@telegram-apps/bridge';
import { PredictProvider } from "../../../../Domain/Predict/predictProdiver";

interface IProps {
    onClose: () => void;
}

enum DepositType {
    Transfer = 1,
    TelegramStars = 2
}

export default function InvoicePaymentModal(props: IProps) {
    const { token, userId, updateGame } = useContext(GameContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [selectedDepositType, setSelectedDepositType] = useState<DepositType>(DepositType.Transfer);
    const [value, setValue] = useState<number | null>(null);
    const [minAmount, setMinAmount] = useState<number>(5);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    async function createDeposit() {
        if (value == null || value <= 0) return toast.error("Specify a valid amount");
        if (value < minAmount) return toast.error(`Min amount - ${minAmount}`);

        setIsLoading(true);
        if (selectedDepositType == DepositType.Transfer) {
            const result = await InvoiceProvider.create(value, token!);
            setIsLoading(false);

            navigate(`/predict/profile/invoice/${result.uuid}`);
        }
        else {
            // defineEventHandlers();

            // const link = await InvoiceProvider.getTelegramLink(value, token!);
            // setIsLoading(false);

            // if (invoice.open.isAvailable()) {
            //     on('invoice_closed', (payload) => {
            //         if (payload.status == 'paid') {
            //             updateBalance();
            //             props.onClose();
            //         }
            //         else if (payload.status == 'cancelled') return;
            //         else {
            //             props.onClose();
            //         }
            //     }, true);

            //     await invoice.open(link, 'url');
            // }
        }
    }

    async function updateBalance() {
        const balanceResult = await PredictProvider.getBalance(token!);
        updateGame({
            score: parseFloat(balanceResult.balance.toString()),
            balance_usdt: parseFloat(balanceResult.balance_usdt.toString()),
            dao_balance: balanceResult.dao_balance,
            balance_usdt_staking: balanceResult.balance_usdt_staking
        });
    }

    function changeSelectDepositType(depositType: DepositType) {
        setSelectedDepositType(depositType);

        if (depositType == DepositType.TelegramStars) setMinAmount(0.1)
        else setMinAmount(5);
    }

    return (
        <div className="invoice-payment-modal" style={{ marginTop: 70 }}>
            <Toaster />
            {isLoading && <PLoader />}
            <div className="modal-content">
                <div className="modal-content-header">
                    <h2>Deposit</h2>
                    <div className="close-button" onClick={props.onClose}>✕</div>
                </div>

                <div className="modal-content-input-block">
                    <div className="modal-content-input-block-title">Specify amount</div>
                    <RoundedNumberInput
                        value={value}
                        onChange={setValue}
                        color='light'
                        placeholder={"0"}
                        autoFocus
                        style={{
                            padding: "8px 9px",
                            fontSize: "28px",
                            borderRadius: "6px",
                            textAlign: "center",
                            marginTop: 5,
                        }}
                    />
                    <div className="modal-content-input-block-subtitle">Min amount - {minAmount}</div>

                    {[939941303, 875591451, 215159309, 463600889].includes(userId) &&
                        <div className="modal-content-input-block-deposit-types">
                            <div onClick={() => changeSelectDepositType(DepositType.Transfer)} className={`${selectedDepositType == DepositType.Transfer ? "active" : ""}`}>{t("Transfer")}</div>
                            <div onClick={() => changeSelectDepositType(DepositType.TelegramStars)} className={`${selectedDepositType == DepositType.TelegramStars ? "active" : ""}`}>{t("Telegram Stars")}</div>
                        </div>
                    }

                </div>

                <div className="deposit-button" onClick={createDeposit}>
                    Deposit
                </div>
            </div>
        </div>
    )
}
