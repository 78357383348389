import React, { useContext, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import coinImage from "../../../Resources/images/coin.svg";
import UsdtIcon from "../../../Resources/Predict/icons/USDT.svg";
import { useTranslation } from 'react-i18next';
import GameContext from '../../../Context/GameContext';
import WebAppUser from "@twa-dev/sdk";
import { UserProvider } from '../../../Domain/User/userProvider';
import { levels_score } from '../../../constants';
import Loader from '../loader/Loader';

export default function MainLayout() {
    const { updateGame, userId, totalEarn, score, coinsPerSecond } = useContext(GameContext);
    const [passiveProfitPopup, setPassiveProfitPopup] = useState<boolean>(false);
    const [passiveProfitValue, setPassiveProfitValue] = useState<number>(0);
    const [passiveProfitUsdtValue, setPassiveProfitUsdtValue] = useState<number>(0);
    const [loaded, setLoaded] = useState<boolean>(false);

    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    function passiveProfitPopupClose() {
        setPassiveProfitPopup(false);
    }

    useEffect(() => {
        if (!userId) return;

        authUser();

        if (location.pathname === '/') navigate(`/${userId}`);
    }, []);


    useEffect(() => {
        levels_score.forEach((level, i) => {
            if (levels_score[i][0] <= totalEarn && levels_score[i][1] >= totalEarn) {
                updateGame({
                    level: i,
                    coinsNeedForNewLevel: levels_score[i][1] - totalEarn
                })
            }
        });
    }, [totalEarn]);

    useEffect(() => {
        const interval = setInterval(() => {
            updateGame(prev => ({
                ...prev,
                score: prev.score + prev.coinsPerSecond,
                energy: prev.energy >= prev.maxEnergy ? prev.energy = prev.maxEnergy : prev.energy + prev.energyPerSecond,
                totalEarn: prev.totalEarn + prev.coinsPerSecond,
                eventTimeEndSecond: prev.eventTimeEndSecond - 1
            }));
        }, 1000);

        return () => clearInterval(interval);
    }, [coinsPerSecond]);


    async function authUser() {
        let user_language = (WebAppUser.initDataUnsafe && WebAppUser.initDataUnsafe.user) ? WebAppUser.initDataUnsafe.user.language_code : "ru";

        const response = await UserProvider.authUser(userId, user_language!, WebAppUser.initDataUnsafe?.start_param,
            WebAppUser.initDataUnsafe.user?.first_name,
            WebAppUser.initDataUnsafe.user?.last_name,
            WebAppUser.initDataUnsafe.user?.username,
            WebAppUser.initDataUnsafe.user?.language_code
        )

        let user_score = parseFloat(response.user.balance);
        let energy = parseInt(response.userurrent_energy);
        let total_earn = parseInt(response.user.total_earn);

        let maxEnergy = parseInt(response.user.max_energy);
        let temp_coins_per_second = parseFloat(response.user.coins_per_second);
        let temp_coins_per_click = parseFloat(response.user.coins_per_click);

        // if(response.user.skin_id !== null){
        //     console.log("есть бусты");
        //     maxEnergy = maxEnergy + (maxEnergy / 100 * parseInt(response.user.energy_bar_boost));
        //     temp_coins_per_second = temp_coins_per_second + (temp_coins_per_second / 100 * parseInt(response.user.earning_boost));
        //     temp_coins_per_click = temp_coins_per_click + parseFloat(response.user.per_tap_boost);
        // }

        let passiveProfitValue = parseFloat(response.user.balance) - parseFloat(response.user.old_balance);
        if (passiveProfitValue !== 0) {
            setPassiveProfitPopup(true);
        }

        setPassiveProfitValue(passiveProfitValue);
        setPassiveProfitUsdtValue(response.passiveProfitUsdtValue);

        user_score = parseFloat(response.user.balance);
        total_earn = total_earn + (parseFloat(response.user.balance) - parseFloat(response.user.old_balance));
        energy = response.user.current_energy;
        const dao_balance = parseFloat(response.user.dao_balance ?? 0);

        updateGame({
            score: user_score,
            coinsPerClick: temp_coins_per_click,
            coinsPerSecond: temp_coins_per_second,
            playerImprovements: JSON.parse(response.user.improvements_data),
            energy: energy,
            maxEnergy: maxEnergy,
            totalEarn: total_earn,
            coinImageId: parseInt(response.user.coin_image_id),
            skinImageId: parseInt(response.user.skin_image_id),
            coinId: parseInt(response.user.coin_id),
            skinId: parseInt(response.user.skin_id),
            coinShadowColor: response.user.coin_shadow_color,
            skinEarningBoost: response.user.earning_boost,
            skinPerTapBoost: response.user.per_tap_boost,
            energyBarBoost: response.user.energy_bar_boost,
            family_id: response.user.family_id ? parseInt(response.user.family_id) : null,
            token: response.token,
            event_clicks_counter: response.user.clicks_counter,
            default_coins_per_second: parseFloat(response.default_coins_per_second),
            default_coins_per_tap: parseInt(response.default_coins_per_tap),
            default_max_energy: parseInt(response.default_max_energy),
            eventTimeEndSecond: parseInt(response.event_end_datetime),
            energyPerSecond: parseInt(response.user.energy_per_second),
            dao_balance: dao_balance,
            balance_usdt_staking: parseFloat(response.user.balance_usdt_staking ?? 0),
            balance_usdt: response.user.balance_usdt ?? 0,
            firstName: response.user.first_name,
            lastName: response.user.last_name,
            username: response.user.username,
        });

        if (parseInt(response.user.coin_image_id) === 9) {
            // document.body.classList.add('golden-pixie-coin-event');
        }

        setLoaded(true);
    }

    if (!loaded || score == null) return <Loader />

    return (
        <div>
            {
                passiveProfitPopup &&
                <div className="offline_profit_container" >
                    <div className="offline_profit_container-content" >
                        <span className="offline_profit_container-content-text" > {t('Income during absence')} </span>
                        < span className="offline_profit_container-content-value" >
                            <img src={coinImage} alt="" />
                            {Math.round(passiveProfitValue).toLocaleString('en')}
                        </span>
                        {passiveProfitUsdtValue != null &&
                            < span className="offline_profit_container-content-value" >
                                <img src={UsdtIcon} alt="" />
                                {(+(passiveProfitUsdtValue).toFixed(4)).toLocaleString('en')}
                            </span>
                        }
                        < button className="offline_profit_container-content-button" onClick={passiveProfitPopupClose} > OK </button>
                    </div>
                    <div className="offline_profit_container-overlay"> </div>
                </div>
            }
            <Outlet />
        </div>
    )
}
